import {
  API_DATE_FORMAT,
  API_DATE_TIME_FORMAT,
  API_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_TIME_FORMAT,
  DISPLAY_TIME_FORMAT_12_HOURS,
  MONTH_FORMAT,
} from '../formats';
import { getDateUtilsInternalLibraryInstance } from '../internalLibrary/instance';
import { DateUtilsInternalLibraryObject } from '../internalLibrary/libraryTypes';

import { DeprecatedDateType } from './deprecatedDateType';

/**
 * @deprecated Use `SerializationDateUtils` instead (moment.js is not supported anymore)
 */
export class SerializationDateUtilsDeprecated {
  public static getMomentFromServerString(
    value: string
  ): DateUtilsInternalLibraryObject {
    return getDateUtilsInternalLibraryInstance()(value, API_DATE_FORMAT);
  }

  public static getMomentWithTimeFromServerString(
    value: string
  ): DateUtilsInternalLibraryObject {
    return getDateUtilsInternalLibraryInstance()(value, API_DATE_TIME_FORMAT);
  }

  public static getDateFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentFromServerString(
      value
    ).toDate();
  }

  public static getDateTimeFromServerString(value: string): Date {
    return SerializationDateUtilsDeprecated.getMomentWithTimeFromServerString(
      value
    ).toDate();
  }

  public static formatDateForApi(
    date: DeprecatedDateType | undefined,
    sourceFormat?: string
  ): string {
    return date
      ? getDateUtilsInternalLibraryInstance()(date, sourceFormat).format(
          API_DATE_FORMAT
        )
      : '';
  }

  public static formatTimeForApi(date: DeprecatedDateType | undefined): string {
    return date
      ? getDateUtilsInternalLibraryInstance()(date).format(API_TIME_FORMAT)
      : '';
  }

  public static formatDateTimeForApi(
    date: DeprecatedDateType | undefined
  ): string {
    return date
      ? getDateUtilsInternalLibraryInstance()(date).format(API_DATE_TIME_FORMAT)
      : '';
  }

  public static formatDateTimeForDisplay(
    dateTime?: DeprecatedDateType,
    inputFormat?: string,
    outputFormat?: string
  ): string {
    const resolvedInputFormat = inputFormat || [
      API_DATE_FORMAT,
      API_TIME_FORMAT,
      API_DATE_TIME_FORMAT,
    ];

    const resolvedOutputFormat =
      outputFormat || `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;

    return getDateUtilsInternalLibraryInstance()(
      dateTime,
      resolvedInputFormat
    ).format(resolvedOutputFormat);
  }

  public static formatTimeTo24(timeString: string): string {
    return getDateUtilsInternalLibraryInstance()(timeString, [
      DISPLAY_TIME_FORMAT_12_HOURS,
    ]).format(DISPLAY_TIME_FORMAT);
  }

  public static formatTimeTo12(timeString: string): string {
    return getDateUtilsInternalLibraryInstance()(timeString, [
      DISPLAY_TIME_FORMAT,
    ]).format(DISPLAY_TIME_FORMAT_12_HOURS);
  }

  public static formatMonth(date: DeprecatedDateType | undefined): string {
    return getDateUtilsInternalLibraryInstance()(date).format(MONTH_FORMAT);
  }

  public static format(
    date: DeprecatedDateType | undefined,
    format: string
  ): string {
    return date
      ? getDateUtilsInternalLibraryInstance()(date).format(format)
      : '';
  }
}
