export interface I18nManifest {
  hashMap: Record<string, string>;
}

// process.env['NODE_ENV'] because rollup plugin replaces `process.env.NODE_ENV` with constants
const isProductionProcess = process.env['NODE_ENV'] === 'production';

const tryParseManifest = (rawManifest: string): I18nManifest => {
  try {
    return JSON.parse(rawManifest);
  } catch (_e) {
    // eslint-disable-next-line no-console
    console.error(
      'Error in parsing translation-manifest.json, did you missed running i18nManifestInjector script?'
    );
  }

  return { hashMap: {} };
};

const SHIJI_TRANSLATIONS_MANIFEST_RAW: string =
  '{"hashMap":{"translations":"PStgZftJPU5ipyK%2B68SJjhvdbD5FW4c8e9866buMd5w%3D"}}';

let I18N_MANIFEST: I18nManifest;

export const getI18nManifest = (): I18nManifest => {
  if (!isProductionProcess) {
    throw new Error('getI18nManifest() is available on production only');
  }

  if (!I18N_MANIFEST) {
    I18N_MANIFEST = isProductionProcess
      ? tryParseManifest(SHIJI_TRANSLATIONS_MANIFEST_RAW)
      : { hashMap: {} };
  }

  return I18N_MANIFEST;
};
