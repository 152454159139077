import { RouteChangeCallbacks } from '../../../routing';
import { ExactCentralReservationOfficeIdentifier } from '../centralReservationOffice';
import { ExactPropertyIdentifier } from '../property';
import { ExactTenantIdentifier } from '../tenant';

export enum BusinessContextSource {
  Global = 'Global',
  Exact = 'Exact',
}

export type BusinessContextFeatureConfig<IIdentifier> =
  | { source: BusinessContextSource.Global }
  | { source: BusinessContextSource.Exact; identifier: IIdentifier };

export type RoutingFeatureConfiguration =
  | false
  | {
      routeChangeCallbacks?: RouteChangeCallbacks;
    };

export interface ShijiEnterprisePlatformFeatureConfig {
  communication: boolean;
  propertyWebsockets: boolean;
  tenantWebsockets: boolean;
  metricsTracker: boolean;
  routing: RoutingFeatureConfiguration;
  authentication: boolean;
  responsiveness: boolean;
  defaultPageLayout: boolean;

  userBusinessContext?: boolean;
  systemBusinessContext?: boolean;
  tenantBusinessContext?: BusinessContextFeatureConfig<ExactTenantIdentifier>;
  centralReservationOfficeBusinessContext?: BusinessContextFeatureConfig<ExactCentralReservationOfficeIdentifier>;
  propertyBusinessContext?: BusinessContextFeatureConfig<ExactPropertyIdentifier>;
  profileCentersContext?: boolean;

  customLoadingView?: JSX.Element;
  moduleClass?: string;
}
