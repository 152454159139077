import { CommunicationEvent } from './event';
import { CommunicationEventCreator } from './event-creator';
import { IEventBus } from './eventBus';

type EventSubscriber<TPayload> = (payload: TPayload) => void;
type Subscribe = (
  eventCreator: CommunicationEventCreator<any>,
  subscriber: EventSubscriber<any>
) => () => void;
type Dispatch = (payload: CommunicationEvent<unknown>) => void;

export class EventBusMockBuilder {
  private dispatch?: Dispatch;
  private subscribe?: Subscribe;

  withSubscribe(subscribe: Subscribe): this {
    this.subscribe = subscribe;

    return this;
  }

  withDispatch(dispatch: Dispatch): this {
    this.dispatch = dispatch;

    return this;
  }

  build(): IEventBus {
    return {
      dispatch: this.dispatch ?? jest.fn(),
      subscribe: this.subscribe ?? jest.fn(),
    };
  }
}
