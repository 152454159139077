export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number = 200
): {
  (...args: Parameters<F>): void;
  cancel(): void;
} => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  debounced.cancel = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  return debounced;
};
