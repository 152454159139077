import { isDefined } from './isDefined';

export const isEmpty = (element: unknown): boolean => {
  if (typeof element === 'boolean') {
    return !element;
  }

  if (!isDefined(element) || Number.isNaN(element)) {
    return true;
  }

  if (typeof element === 'string' || Array.isArray(element)) {
    return element.length === 0;
  }

  if (element instanceof Date) {
    return isNaN(element.getTime());
  }

  if (
    typeof element === 'object' &&
    Object.keys(element as object).length === 0
  ) {
    return true;
  }

  return false;
};
